import React from "react"
import Helmet from "react-helmet"
import SEO from "../../components/seo"
import Layout from "../../components/layout"

export default function CustomerSupportServices() {
  return (
    <Layout>
      <SEO title="Customer Support Services Policy | Compute Software"/>
      <Helmet></Helmet>
      <section className="terms-hero sub-hero jumbotron jumbotron-fluid">
        <div className="container">
          <h1 className="page-title">Customer Support Services Policy</h1>
          <p className="last-updated">Last updated: January 10, 2021</p>
        </div>
      </section>
      <section className="terms-content sub-general-content scrollable-content">
        <div className="container">
          <div className="row">
            <div className="terms col-lg-12">
                <main className="full-width">
                  <div className="content">
                    <p>If Customer is a party to a mutually executed agreement that specifically references this Customer Support Services Policy as applicable (such as the Compute Software Services Agreement) then customer support is included without additional charge in accordance with the below.</p>
                    <p>Company will provide Customer support via email on weekdays during the hours of 8:00 am through 5:00 pm Pacific Time, with the exclusion of United States Federal Holidays (&ldquo;Support Hours&rdquo;).</p>
                    <p>Customer may initiate a support request at any time by emailing a description of the issue or request to <a href="mailto:support@computesoftware.com">support@computesoftware.com</a>.&nbsp;</p>
                    <table>
                      <tbody>
                        <tr>
                          <td>Priority Level</td>
                          <td>Contact Method</td>
                          <td>Initial Response Time</td>
                          <td>Solution Definition (one or more of the following)</td>
                        </tr>
                        <tr>
                          <td>1 (Critical)</td>
                          <td>Email</td>
                          <td>8 hours</td>
                          <td><ul>
                              <li>Issue is resolved</li>
                              <li>Satisfactory workaround is provided</li>
                              <li>Product patch is provided</li>
                              <li>Fix incorporated into future release</li>
                            </ul></td>
                        </tr>
                        <tr>
                          <td>2 (High)</td>
                          <td>Email</td>
                          <td>24 hours</td>
                          <td><ul>
                              <li>Issue is resolved</li>
                              <li>Satisfactory workaround is provided</li>
                              <li>Product patch is provided</li>
                              <li>Fix incorporated into future release</li>
                            </ul></td>
                        </tr>
                        <tr>
                          <td>3 (Medium)</td>
                          <td>Email</td>
                          <td>48 hours</td>
                          <td><ul>
                              <li>Issue is resolved</li>
                              <li>Satisfactory workaround is provided</li>
                              <li>Product patch is provided</li>
                              <li>Fix incorporated into future release</li>
                            </ul></td>
                        </tr>
                        <tr>
                          <td>4 (Low)</td>
                          <td>Email</td>
                          <td>72 hours</td>
                          <td><ul>
                              <li>Answer to question is provided</li>
                              <li>Enhancement request logged</li>
                            </ul></td>
                        </tr>
                      </tbody>
                    </table>
                    <p><strong>Priority Levels</strong></p>
                    <table>
                      <tbody>
                        <tr>
                          <td>Priority Level</td>
                          <td>Description</td>
                        </tr>
                        <tr>
                          <td>1 (Critical)</td>
                          <td><strong>Emergency Issue</strong>Full or partial system outage, significant performance degradation, or a condition caused by the Compute Software Software that creates data integrity issues making the product unusable or unavailable for all users in production.</td>
                        </tr>
                        <tr>
                          <td>2 (High)</td>
                          <td><strong>Significant Business Impact</strong>A condition caused by the Compute Software software where major functionality is impacted or significant performance degradation is experienced. Issue is persistent and affects many users and/or major functionality.</td>
                        </tr>
                        <tr>
                          <td>3 (Medium)</td>
                          <td><strong>Minor Feature / Function Issue</strong>A component of Compute Software is not performing as expected or documented. System performance issue or bug.</td>
                        </tr>
                        <tr>
                          <td>4 (Low)</td>
                          <td><strong>Minor Problem / Enhancement Request</strong>Inquiry regarding a routine technical issue; information requested on application capabilities, navigation, installation, or configuration; bug; enhancement request.</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </main>
              </div>
            </div>
        </div>
      </section>
    </Layout>
  )
}
